<template>
  <GridCell
    :key="day.dayName"
    class="job-group__day"
    :disabled="checkRestriction(day, timesheetJob).disabled && duration == 0"
  >
    <div
      v-if="!(checkRestriction(day, timesheetJob).disabled && duration == 0)"
      :key="day.dayName"
      :class="[
        'height-100',
        {
          'job-group__day--rejected':
            isTimesheetRejected(
              !timesheetJob.isClarizenAssignedProject &&
                !timesheetJob.selfAssignmentWithManageTasking
                ? getTimesheets(timesheets, timesheetJob.id, day.date)
                : getTimesheetsDuplicate(
                    this.timesheets,
                    timesheetJob.clarizenProjectId,
                    day.date,
                    true,
                    timesheetJob.isClarizenTask,
                    timesheetJob.clarizenTaskExternalId,
                    timesheetJob.task,
                    currentDayJobFeatures?.isHistoricalJob
                  )
            ).length && !(isContractor && agencyData.hideTimesheetWorkcode),
          'job-group__day--weekend': day.isWeekend,
          'job-group__day--holiday': day.isHoliday,
          'timesheet-hidden-job-cell': isJobHidden(
            hoursTotalPerJob,
            timesheetJob,
            jobIndex
          ),
        },
      ]"
      :aria-label="`${
        isFutureDate
          ? $t(
              'timesheetUI.ValidationMessages.TimesheetEntryFutureNotAllowedMessage'
            )
          : cellValueAndLabel(day).value
      }`"
    >
      <div
        v-if="
          checkRestriction(day, timesheetJob).disabled &&
          !timesheetDialogVisibility
        "
        class="disable-click-handler"
        @click="
          () =>
            handleResticted(
              checkRestriction(day, timesheetJob),
              timesheetJob,
              day,
              timesheetJob.isClarizenAssignedProject &&
                timesheetJob.clarizenProjectId
            )
        "
      ></div>
      <TimesheetDayCellStatusIcons
        v-if="
          day.isRequiredByUserContract &&
          !currentDayJobFeatures?.availablefeatures?.features
            ?.temporaryExternalEmployee?.required &&
          !(isContractor && agencyData.hideTimesheetWorkcode)
        "
        :day="day"
        :timesheetJob="timesheetJob"
      />
      <TimeEntryCell
        :id="`${(timesheetJob.id + day.date.split('T')[0]).replaceAll(
          '-',
          ''
        )}`"
        :alwaysAllowEdit="true"
        :value="duration"
        :multiple="getTimesheetEntryCount(day.date) > 1"
        :day="day"
        @input="handleDurationInput"
        @change="debouncedSave"
        @dblclick="handleCellDblClick"
        @enterdown="handleEnterDown"
        @focus="handleInputFocus"
        :readonly="
          isSingleEntryApproved ||
          isTimeEntryDrawerOpen ||
          isTimesheetSaveInProgress ||
          loading ||
          isAsyncValidationInProgress
        "
        :disabled="
          checkRestriction(day, timesheetJob).disabled ||
          isFutureDate ||
          navigationLoading
        "
        :error="isTimesheetSaveInProgress ? '' : error"
        :loading="isTimesheetSaveInProgress || isAsyncValidationInProgress"
        @blur="handleBlur"
        :should-glow="shouldGlow"
        :jobId="timesheetJob.id"
        :jobName="timesheetJob.jobName"
        :active-cell="activeCell"
      />
    </div>
  </GridCell>
</template>

<script>
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex'
import moment from 'moment'
import { debounce } from 'lodash'

import {
  getTimesheets,
  isTimesheetRejected,
  checkRestriction,
  getTimesheetsDuplicate,
  isJobHidden,
  getBillableIndicatorName,
} from '@Shared/filters'
import TimesheetDayCellStatusIcons from '../TimesheetDayCellStatusIcons.vue'
import TimeEntryCell from '../time-entry/TimeEntryCell.vue'
import GridCell from '../grid/GridCell.vue'
import {
  timesheetsForDay,
  durationValueValidation,
  getTimesheetRequiredFields,
  isActivityRequired,
  validForEntry,
  getLocationWfhSettings,
} from '@/utils/timesheetHelper'
import { cloneDeep } from 'lodash'
import { omit } from 'lodash'
import { EventBus } from '@Shared/EventBus'

export default {
  name: 'TimesheetCell',
  props: ['day', 'timesheetJob', 'jobIndex', 'openActions', 'dayIndex'],
  components: {
    TimesheetDayCellStatusIcons,
    GridCell,
    TimeEntryCell,
  },
  data() {
    return {
      error: '',
      duration: 0,
      details: {},
      isDrawerError: false,
      shouldValidationStart: false,
      validationAndSaveInProgress: false,
      currentCellJobId: `${this.timesheetJob.id}--${this.day.date}`,
      isTimesheetSaveInProgress: false,
      timesheetDetailLocal: {},
      isNewEntry: null,
      hasBlurTriggered: false,
      isAsyncValidationInProgress: false,
      isCellFocused: false,
      shouldGlow: false,
    }
  },
  computed: {
    ...mapGetters('storeTimesheets', [
      'timesheetJobs',
      'timesheetExists',
      'hoursTotalPerJob',
      'hoursTotalPerDay',
      'newTimesheetObj',
      'isLocationActiveForThisDay',
      'currentDayJobFeatures',
      'isContractor',
    ]),
    ...mapState('storeTimesheets', [
      'isTimesheetBeingCorrected',
      'timesheetDetailFeatures',
      'timesheetSelectedJob',
      'isTimeEntryDrawerOpen',
      'isInlineErrorResolved',
      'timesheetCellDetails',
      'timesheetDetail',
      'timesheetExists',
      'agencyData',
      'timesheets',
      'observer',
      'savedIds',
      'preparingForAutoSave',
      'updateCellValuesAfterTimsheetUpdateFromDrawer',
      'timesheetSelectedJob',
      'loading',
      'userSettings',
      'locationList',
      'activeCell',
      'unfilteredTimesheets',
      'updateCellStateAfterTimsheetUpdateFromLocation',
      'navigationLoading',
    ]),
    isSingleEntryApproved() {
      const currentDaySheet = timesheetsForDay(
        this.timesheetJob,
        this.timesheets,
        this.day.date,
        true
      )
      return (
        this.timesheetExists &&
        this.timesheetExists.length === 1 &&
        currentDaySheet.some(
          (t) => t.timesheetStatus.toLowerCase() === 'approved'
        )
      )
    },
    isFutureDate() {
      return moment(new Date(this.day.date)).isAfter(
        moment().format('YYYY-MM-DD')
      )
    },

    timesheetDetailDurationUpdated() {
      if (!this.timesheetDetail) return false
      return `${this.timesheetDetail.durationValue} ${this.timesheetDetail.durationPercentageValue}`
    },
    shouldUpdateRole() {
      const isAllowed = validForEntry(
        this.timesheetExists,
        this.timesheetJob,
        this.day.date
      )

      const activity = isActivityRequired(this.timesheetJob, this.day.date)

      return !isAllowed && !activity
    },

    isDurationDirty() {
      if (this.duration == this.timesheetDetailLocal[this.getDurationKey()])
        return false

      if (
        parseFloat(this.duration) === 0 &&
        [0, '0', undefined].includes(
          this.timesheetDetailLocal[this.getDurationKey()]
        )
      ) {
        return false
      }

      return true
    },

    isNewEntryBeingCreatedOnADayWithExistingTimesheet() {
      if (
        this.isTimeEntryDrawerOpen &&
        this.timesheetExists.length > 0 &&
        this.timesheetDetail &&
        !this.timesheetDetail.id
      ) {
        return true
      }
      return false
    },
    currentDayJobFeatures() {
      return this.timesheetJob?.availableFeaturesBillableIndicators?.find(
        (feat) =>
          moment(feat.date, 'DD/MM/YYYY').format('DD-MM-YYYY') ===
          moment(this.day.date).format('DD-MM-YYYY')
      )
    },
    isDrawerOpenFroCurrentCell() {
      return (
        this.timesheetSelectedJob.date === this.day.date &&
        this.timesheetSelectedJob.job.id === this.timesheetJob.id
      )
    },
    // this.timesheetSelectedJob.date !== this.day.date ||
    //     this.timesheetSelectedJob.job.id !== this.timesheetJob.id
  },
  methods: {
    ...mapActions('storeTimesheets', [
      'saveTimesheet',
      'addNewTimesheet',
      'saveTimesheetCell',
      'loadValidationLogic',
      'loadBillableIndicators',
      'fetchAvailableWorkcodesAndBillableIndicators',
    ]),
    ...mapMutations('storeTimesheets', [
      'setTimesheetExists',
      'setTimesheetSelectedJob',
      'resetTableToSave',
      'revertTimesheet',
      'setTimesheetDetail',
      'setIsTimesheetBeingCorrected',
      'setIsTimesheetBeingReviewed',
      'setTimesheetDetailOld',
      'showTimeEntryDrawer',
      'hideTimeEntryDrawer',
      'setInlineErrorStatus',
      'setAlertWeekErrors',
      'setBeforeEdit',
      'addCellTimesheetDetail',
      'removeCellTimesheetDetail',
      'setTimesheets',
      'setTimeEntryError',
      'setCopyRestOfWeekSelected',
      'setUnfilteredTimesheets',
      'setActiveCell',
      'removeFromUpdateCellStateAfterTimsheetUpdateFromLocation',
      'setCommentValue',
      'clearCommentValue',
    ]),
    getTimesheets,
    getTimesheetsDuplicate,
    isTimesheetRejected,
    isJobHidden,
    getLocationWfhSettings,
    checkRestriction(day, timesheetJob) {
      let durationValue = 0

      if (
        timesheetJob.isClarizenAssignedProject &&
        timesheetJob.isClarizenTask
      ) {
        durationValue = getTimesheetsDuplicate(
          this.timesheets,
          timesheetJob.clarizenProjectId,
          day.date,
          false,
          timesheetJob.isClarizenTask,
          timesheetJob.clarizenTaskExternalId,
          timesheetJob.task,
          this.currentDayJobFeatures?.isHistoricalJob
        )
      } else {
        durationValue = getTimesheets(
          this.timesheets,
          timesheetJob.id,
          day.date,
          'durationValue'
        )
      }

      return checkRestriction(day, timesheetJob, durationValue)
    },
    isToday: function (day) {
      return moment().isSame(day.date, 'day')
    },
    getTimesheetEntryCount(date) {
      return (getTimesheets(this.timesheets, this.timesheetJob.id, date) || [])
        .length
    },
    cellValueAndLabel(day) {
      const defaultValue =
        getTimesheets(
          this.timesheets,
          this.timesheetJob.id,
          day.date,
          'durationValue'
        ) || 0

      const ppmValue = getTimesheetsDuplicate(
        this.timesheets,
        this.timesheetJob.clarizenProjectId,
        day.date,
        false,
        this.timesheetJob.isClarizenTask,
        this.timesheetJob.clarizenTaskExternalId,
        this.timesheetJob.task,
        this.currentDayJobFeatures?.isHistoricalJob
      )

      const defaultDurationAriaLabel = `${
        this.isToday(day) ? 'Current day, ' : ''
      }${day.dayNameLong} ${day.day}th, Job Name: ${
        this.timesheetJob.jobName
      } - Time entered: ${defaultValue}, Number of Entries on day: ${this.getTimesheetEntryCount(
        day.date
      )}`

      const ppmDurationAriaLabel = `${day.dayNameLong} ${
        day.day
      }th, Job Name: ${
        this.timesheetJob.jobName
      } - Time entered: ${ppmValue}, Number of Entries on day: ${this.getTimesheetEntryCount(
        day.date
      )}`

      const isPPM =
        (this.timesheetJob.isClarizenAssignedProject &&
          this.timesheetJob.isClarizenTask) ||
        this.timesheetJob.selfAssignmentWithManageTasking

      if (isPPM) {
        // this.duration = ppmValue
        return {
          ariaLabel: ppmDurationAriaLabel,
          value: ppmValue,
        }
      }

      // this.duration = defaultValue

      return {
        ariaLabel: defaultDurationAriaLabel,
        value: defaultValue,
      }
    },
    openTimesheetEntryDrawer() {
      if (this.isTimeEntryDrawerOpen) {
        this.resetDrawerState()
      }

      this.openActions.openTimesheetDialog(
        this.timesheetJob,
        this.day.date,
        (this.timesheetJob.isClarizenAssignedProject ||
          this.timesheetJob.selfAssignmentWithManageTasking) &&
          this.timesheetJob.clarizenProjectId
      )
    },
    resetDrawerState() {
      this.resetTableToSave()
      this.setCopyRestOfWeekSelected()
      if (this.observer && this.observer.flags.dirty) {
        this.revertTimesheet(this.beforeEdit)
      }

      this.observer.reset()
      this.setTimesheetDetail({ data: null, openDrawer: false })
      this.setTimesheetExists(false)
      this.setIsTimesheetBeingCorrected(false)
      this.setIsTimesheetBeingReviewed(false)
      this.setTimesheetDetailOld(null)
    },
    handleCellDblClick() {
      this.setCopyRestOfWeekSelected(false)
      if (
        (!this.isMultipleEntry() && this.isDurationDirty && !this.error) ||
        this.isAsyncValidationInProgress ||
        this.isTimesheetSaveInProgress
      )
        return

      this.openTimesheetEntryDrawer()
    },
    handleValidation(durationValue) {
      const dayIndex =
        moment(this.timesheetSelectedJob.date.split('T')[0]).isoWeekday() - 1

      return durationValueValidation(
        durationValue,
        {
          timesheetDetail: this.timesheetDetail,
          timesheetDetailFeatures: this.timesheetDetailFeatures,
          isTimesheetBeingCorrected: this.isTimesheetBeingCorrected,
        },
        this.hoursTotalPerDay,
        !this.timesheetCellDetails.get(this.currentCellJobId)?.id ?? false,
        dayIndex,
        this.agencyData.timesheetDurationStep,
        false,
        this.getDurationKey() === 'durationPercentageValue',
        this.timesheetDetailLocal[this.getDurationKey()]
      )
    },
    validateBillableIndicator(features) {
      let isBillableValid = false
      let billable = null

      let billableIndicator = features?.billableIndicators.find(
        (indicator) => indicator.selected
      )

      if (features?.billableIndicators.length === 1) {
        billableIndicator = features?.billableIndicators[0]
      }

      if (billableIndicator) {
        billable = billableIndicator
        isBillableValid = true
      }

      return { isBillableValid, billable }
    },
    getDurationKey() {
      const currentDayJob =
        this.timesheetJob?.availableFeaturesBillableIndicators?.find(
          (feat) =>
            moment(feat.date, 'DD/MM/YYYY').format('DD-MM-YYYY') ===
            moment(new Date(this.day.date)).format('DD-MM-YYYY')
        )

      const isPercentageJob =
        currentDayJob?.availablefeatures?.features?.percentageOrDuration?.data
          .isPercentageAgency

      return isPercentageJob ? 'durationPercentageValue' : 'durationValue'
    },

    async validateTimesheetDetail(durationValue) {
      const valid = {
        openDrawer: false,
        showError: false,
      }
      const updatedDetailValues = {}
      const durationKey = this.getDurationKey()
      updatedDetailValues[durationKey] = durationValue
      // this.details[durationKey] = durationValue
      if (!parseFloat(durationValue)) return valid

      this.addCellTimesheetDetail({
        jobId: this.currentCellJobId,
        details: {
          ...this.timesheetCellDetails.get(this.currentCellJobId),
          ...updatedDetailValues,
        },
      })

      if (durationKey === 'durationPercentageValue') {
        updatedDetailValues.isPercentageEntry = true
        // this.details.isPercentageEntry = true
      }
      let openDrawer = false
      let showError = false
      let showRuleError = false

      const requiredFields = getTimesheetRequiredFields(
        this.timesheetJob,
        this.day.date
      )

      const param = {
        userJobId: this.timesheetJob.id,
        fromDate: this.day.date.split('T')[0],
        toDate: this.day.date.split('T')[0],
      }

      const features = await this.fetchAvailableWorkcodesAndBillableIndicators(
        param
      )

      if (features.availablefeatures?.features?.workcode?.required) {
        requiredFields.workcode =
          features.availablefeatures.features.workcode.data ?? false
      }

      if (features.availablefeatures?.features?.activity?.required)
        requiredFields.activity =
          features.availablefeatures.features.activity.data ?? false

      const { isBillableValid, billable } =
        this.validateBillableIndicator(features)

      if (!isBillableValid) {
        // this.timesheetDetailLocal[this.getDurationKey()] = 0
        // this.addCellTimesheetDetail({
        //   jobId: this.currentCellJobId,
        //   details: {
        //     ...this.timesheetCellDetails.get(this.currentCellJobId),
        //     [this.getDurationKey()]: 0,
        //   },
        // })
        return {
          openDrawer: true,
          showError: true,
        }
      }

      updatedDetailValues.billableIndicator = billable

      if (!requiredFields) {
        return valid
      }

      const currentCell = this.timesheetCellDetails.get(this.currentCellJobId)

      const keys = Object.keys(requiredFields)
      keys.forEach((key) => {
        if (key === 'activity' && !requiredFields[key]?.activities?.length) {
          showError = true
          showRuleError = true
          openDrawer = false
        }
        if (
          key === 'workcode' &&
          (!requiredFields[key] || !requiredFields[key]?.workCodes.length)
        ) {
          showRuleError = true
          showError = true
          openDrawer = true
        }

        if (key === 'workcode' && requiredFields[key] && !currentCell[key]) {
          let selectedWorkcode = requiredFields[key]?.workCodes?.find(
            (code) => code.selected
          )

          if (requiredFields[key]?.workCodes.length === 1) {
            selectedWorkcode = requiredFields[key]?.workCodes[0]
          }

          if (selectedWorkcode) {
            updatedDetailValues.workCode = selectedWorkcode.value
            updatedDetailValues.workCodeName =
              selectedWorkcode?.label.split(' - ')[1] ?? ''
          } else {
            openDrawer = true
            showError = true
            // this.timesheetDetailLocal[this.getDurationKey()] = 0
            // this.addCellTimesheetDetail({
            //   jobId: this.currentCellJobId,
            //   details: {
            //     ...currentCell,
            //     [this.getDurationKey()]: 0,
            //   },
            // })
          }
        }
      })

      this.timesheetDetailLocal = {
        ...this.timesheetDetailLocal,
        ...updatedDetailValues,
      }

      this.addCellTimesheetDetail({
        jobId: this.currentCellJobId,
        details: {
          ...this.timesheetCellDetails.get(this.currentCellJobId),
          ...updatedDetailValues,
        },
      })
      return { openDrawer, showError, showRuleError }
    },

    async handleValidationAndSave(isTriggredByBlur = false) {
      this.error = null

      // if (
      //   this.duration === this.cellOriginalValues.get(this.currentCellJobId)
      // ) {
      //   // this.error = null
      //   this.resetDurationValues()
      //   return
      // }

      const durationKey = this.getDurationKey()
      this.shouldValidationStart = false

      if (this.isNewEntry) {
        this.isAsyncValidationInProgress = true

        const { openDrawer, showError, showRuleError } =
          await this.validateTimesheetDetail(this.duration)
        this.isAsyncValidationInProgress = false

        if (showRuleError) {
          this.error = this.$t('timesheetUI.SearchJobs.NoJobs')
          this.$toast.error(this.$t('timesheetUI.SearchJobs.NoJobs'))
          // this.setAlertWeekErrors({
          //   date: this.day.date,
          //   error: this.$t('timesheetUI.SearchJobs.NoJobs'),
          // })

          return
        }

        if (showError) {
          this.error = this.$t(
            'timesheetUI.ValidationMessages.MissingRequiredFields'
          )
          this.setInlineErrorStatus({
            jobId: `${this.timesheetJob.id}--${this.day.date}`,
            isResolved: false,
            type: 'validation',
          })
          this.isDrawerError = true
        }

        if (openDrawer) {
          this.$toast.warning(
            this.$t('timesheetUI.ValidationMessages.MissingRequiredFields')
          )
          // this.setAlertWeekErrors({
          //   date: this.day.date,
          //   error: this.$t(
          //     'timesheetUI.ValidationMessages.MissingRequiredFields'
          //   ),
          // })
          this.isAsyncValidationInProgress = false
          return
        }
        this.isAsyncValidationInProgress = false

        if (showError || (openDrawer && !isTriggredByBlur)) return
      }

      if (this.timesheetExists && this.timesheetExists.length > 1) return

      if (this.timesheetExists && this.timesheetExists.length === 1) {
        const existDetails = this.timesheetCellDetails.get(
          this.currentCellJobId
        )
        this.addCellTimesheetDetail({
          jobId: this.currentCellJobId,
          details: existDetails
            ? { ...existDetails, [durationKey]: this.duration }
            : this.timesheetExists[0],
        })

        this.timesheetExists[0] = {
          ...this.timesheetExists[0],
          [durationKey]: this.duration,
        }
      }

      this.debouncedSave()
    },
    validateDuration() {
      this.error = null
      const isValid = this.handleValidation(this.duration)

      if (typeof isValid === 'string') {
        this.error = isValid
        this.setInlineErrorStatus({
          jobId: `${this.timesheetJob.id}--${this.day.date}`,
          isResolved: false,
          type: 'validation',
        })
      }
      return isValid
    },

    handleEnterDown() {
      if (
        (!this.isMultipleEntry() && this.isDurationDirty && !this.error) ||
        this.isAsyncValidationInProgress ||
        this.isTimesheetSaveInProgress
      )
        return

      this.openTimesheetEntryDrawer()
    },

    handleDurationInput(durationValue) {
      this.duration = durationValue
      this.shouldValidationStart = true
      const previousError = this.error

      let isPercentageEntry = false

      if (this.isNewEntry && durationValue == 0) {
        this.error = null
        this.setInlineErrorStatus({
          jobId: this.currentCellJobId,
          isResolved: true,
          type: 'validation',
        })
        return
      }

      // if (this.shouldUpdateRole) {
      //   this.$toast.error(this.$t('timesheetUI.SearchJobs.NoJobs'))
      //   return
      // }

      const durationKey = this.getDurationKey()

      if (durationKey === 'durationPercentageValue') {
        isPercentageEntry = true
      }

      this.addCellTimesheetDetail({
        jobId: this.currentCellJobId,
        details: {
          ...this.timesheetCellDetails.get(this.currentCellJobId),
          [durationKey]: this.duration,
          isPercentageAgency: isPercentageEntry,
          isPercentageEntry: isPercentageEntry,
          reportedDate: this.day.date,
          userJobId: this.timesheetJob.id,
        },
      })
      // this.timesheetDetailLocal[durationKey] = this.duration
      // this.timesheetDetailLocal.isPercentageAgency = isPercentageEntry

      if (this.isNewEntry && !durationValue) return

      let openDrawer = false
      let showError = false
      let showRuleError = false

      const isDurationValid = this.validateDuration()

      if (typeof isDurationValid === 'string') return

      const requiredFields = getTimesheetRequiredFields(
        this.timesheetJob,
        this.day.date
      )

      const keys = Object.keys(requiredFields)

      keys.forEach((key) => {
        if (durationValue == 0) return
        // if (key === 'activity' && !requiredFields[key]?.activities?.length) {
        //   showError = true
        //   showRuleError = true
        //   openDrawer = false
        //   return
        // }

        if (['activity'].includes(key) && this.isNewEntry) {
          openDrawer = true
          showError = true
          return
        }

        if (
          ['task'].includes(key) &&
          this.timesheetJob.availableFeaturesBillableIndicators[this.dayIndex]
            .isHistoricalJob &&
          this.isNewEntry
        ) {
          openDrawer = true
          showError = true
          return
        }

        if (
          key === 'location' &&
          this.isLocationActiveForThisDay(this.day.date) &&
          (!this.timesheetDetailLocal?.location ||
            !this.timesheetDetailLocal?.locationCode)
        ) {
          openDrawer = true
          showError = true
          return
        }
      })

      if (
        this.agencyData.isWFHActive &&
        this.isLocationActiveForThisDay(this.day.date) &&
        [undefined, ''].includes(this.timesheetDetailLocal?.['wfh'])
      ) {
        // openDrawer = true
        showError = true
      }

      if (showRuleError) {
        this.error = this.$t('timesheetUI.SearchJobs.NoJobs')
        this.$toast.error(this.$t('timesheetUI.SearchJobs.NoJobs'))
      }

      if (showError) {
        this.isDrawerError = true
        this.error = this.$t(
          'timesheetUI.ValidationMessages.MissingRequiredFields'
        )

        const isPreviousErrorSameAsCurrentError =
          previousError !==
          this.$t('timesheetUI.ValidationMessages.MissingRequiredFields')

        isPreviousErrorSameAsCurrentError &&
          this.$toast.warning(
            this.$t('timesheetUI.ValidationMessages.MissingRequiredFields')
          )

        this.setInlineErrorStatus({
          jobId: `${this.timesheetJob.id}--${this.day.date}`,
          isResolved: false,
          type: 'validation',
        })
      }

      if (showError) return

      if (openDrawer) {
        // this.openTimesheetEntryDrawer()
        // return
      }
      if (showRuleError || showError) return

      this.debouncedHandleTimesheetDetailsLocalUpdateAndAsyncValidation()
    },

    handleBlur() {
      this.isCellFocused = false

      if (this.isTimeEntryDrawerOpen) return

      if (this.duration === '') {
        this.duration = this.cellValueAndLabel(this.day).value
        this.error = ''
        return
      }

      if (this.isAsyncValidationInProgress || this.isTimesheetSaveInProgress)
        return

      if (this.error) return
      this.hasBlurTriggered = true

      if (!this.isMultipleEntry())
        this.handleTimesheetDetailsLocalUpdateAndAsyncValidation()
    },

    updateDuration() {
      let isPercentageEntry = false
      const durationKey = this.getDurationKey()
      if (isNaN(this.duration) || this.duration === '')
        return { durationChanged: false }

      if (durationKey === 'durationPercentageValue') {
        isPercentageEntry = true
      }

      if (this.duration == this.timesheetDetailLocal[durationKey])
        return { durationChanged: false }

      if (
        parseFloat(this.duration) === 0 &&
        [0, '0', undefined, ''].includes(this.timesheetDetailLocal[durationKey])
      ) {
        return { durationChanged: false }
      }

      this.timesheetDetailLocal = {
        ...this.timesheetDetailLocal,
        userJobId: this.timesheetJob.id,
        reportedDate: this.day.date,
        [durationKey]: parseFloat(this.duration),
        isPercentageEntry,
      }
      this.duration = parseFloat(this.duration)
      return { durationChanged: true }
    },

    handleTimesheetDetailsLocalUpdateAndAsyncValidation() {
      if (this.error) return

      const hasDurationChanged = this.updateDuration()

      if (!hasDurationChanged.durationChanged) return

      if (this.isNewEntry && this.duration == 0) return

      this.handleValidationAndSave()
    },

    handleInputFocus() {
      // console.log(
      //   this.timesheetDetailLocal.altairCode,
      //   !this.timesheetDetail,
      //   !this.error
      // )

      // if (
      //   this.timesheetDetailLocal.altairCode &&
      //   !this.timesheetDetail &&
      //   !this.error
      // ) {
      //   this.duration = this.timesheetDetailLocal[this.getDurationKey()]
      //   this.addCellTimesheetDetail({
      //     details: {
      //       ...this.timesheetCellDetails.get(this.currentCellJobId),
      //       [this.getDurationKey()]: this.duration,
      //     },
      //   })
      // }
      this.setActiveCell(
        `${(this.timesheetJob.id + this.day.date.split('T')[0]).replaceAll(
          '-',
          ''
        )}`
      )

      this.setTimesheetSelectedJob({
        job: this.timesheetJob,
        date: this.day.date,
        openDrawer: this.isTimeEntryDrawerOpen,
      })

      if (
        this.isTimeEntryDrawerOpen &&
        this.timesheetSelectedJob.date.split('T')[0] ===
          this.timesheetDetail?.reportedDate.split('T')[0] &&
        this.timesheetSelectedJob.job.id === this.timesheetDetail?.userJobId
      ) {
        return
      }
      this.hasBlurTriggered = false
      this.isCellFocused = true
      this.resetTableToSave()

      if (this.observer && this.observer.flags.dirty) {
        this.revertTimesheet(this.beforeEdit)
      }

      this.observer?.reset()
      // this.setTimesheetDetail({
      //   data: null,
      //   openDrawer: this.isTimeEntryDrawerOpen,
      // })
      if (
        this.timesheetDetail &&
        this.timesheetDetail.userJobId !== this.timesheetJob.id
      ) {
        this.setCopyRestOfWeekSelected()
      }

      // this.setTimesheetExists(false)
      // this.setIsTimesheetBeingCorrected(false)
      // this.setIsTimesheetBeingReviewed(false)
      // this.setTimesheetDetailOld(null)

      // this.setTimesheetSelectedJob({
      //   job: this.timesheetJob,
      //   date: this.day.date,
      //   openDrawer: this.isTimeEntryDrawerOpen,
      // })

      // if (this.isNewEntry != null) return
      this.setTimesheetDetail({
        data: null,
        openDrawer: this.isTimeEntryDrawerOpen,
      })

      this.setTimesheetExists(false)
      this.setIsTimesheetBeingCorrected(false)
      this.setIsTimesheetBeingReviewed(false)
      this.setTimesheetDetailOld(null)

      const clarizenProjectId = this.timesheetJob.clarizenProjectId
      if (
        (this.timesheetJob.isClarizenAssignedProject &&
          this.timesheetJob.isClarizenTask) ||
        this.timesheetJob.selfAssignmentWithManageTasking
      ) {
        if (
          getTimesheetsDuplicate(
            this.timesheets,
            clarizenProjectId,
            this.day.date,
            true,
            this.timesheetJob.isClarizenTask,
            this.timesheetJob.clarizenTaskExternalId,
            this.timesheetJob.task,
            this.currentDayJobFeatures?.isHistoricalJob
          ).length > 0
        ) {
          const newTimesheets = getTimesheetsDuplicate(
            this.timesheets,
            clarizenProjectId,
            this.day.date,
            true,
            this.timesheetJob.isClarizenTask,
            this.timesheetJob.clarizenTaskExternalId,
            this.timesheetJob.task,
            this.currentDayJobFeatures?.isHistoricalJob
          )
          this.setBeforeEdit(JSON.parse(JSON.stringify(newTimesheets)))
          this.setTimesheetExists(cloneDeep(newTimesheets))
          if (this.timesheetExists.length === 1) {
            this.timesheetExists[0].toSave = true
            this.timesheetDetailLocal = cloneDeep({
              ...this.timesheetDetailLocal,
              ...this.timesheetExists[0],
            })
            if (this.isNewEntry === null) {
              this.addCellTimesheetDetail({
                jobId: this.currentCellJobId,
                details: {
                  ...this.timesheetDetailLocal,
                  reportedDate: this.day.date,
                },
              })

              // this.setCellOriginalValue({
              //   jobId: this.currentCellJobId,
              //   value: this.duration,
              // })
            }
          }
          this.isNewEntry = false
        } else {
          const value = this.timesheetCellDetails.get(this.currentCellJobId)
          if (value) {
            this.setTimesheetDetail({
              data: cloneDeep(value),
              openDrawer: this.isTimeEntryDrawerOpen,
            })
            this.timesheetDetailLocal = value
          } else {
            this.addNewTimesheet({ showDrawer: this.isTimeEntryDrawerOpen })
          }
          // this.addNewTimesheet({ showDrawer: this.isTimeEntryDrawerOpen })
          this.timesheetDetailLocal = cloneDeep({
            ...this.timesheetDetail,
            ...omit(this.timesheetDetailLocal, [
              'location',
              'locationCode',
              'locationLabel',
              'countryCode',
              'wfh',
              // 'reportedDate',
            ]),
          })
          if (this.isNewEntry === null) {
            this.addCellTimesheetDetail({
              jobId: this.currentCellJobId,
              details: {
                ...this.timesheetDetailLocal,
                reportedDate: this.day.date,
              },
            })
          }
          // this.setCellOriginalValue({
          //   jobId: this.currentCellJobId,
          //   value: this.duration,
          // })
          this.isNewEntry = true
        }
      } else {
        if (
          getTimesheets(this.timesheets, this.timesheetJob.id, this.day.date)
            .length > 0
        ) {
          const newTimesheets = getTimesheets(
            this.timesheets,
            this.timesheetJob.id,
            this.day.date
          )
          this.setBeforeEdit(JSON.parse(JSON.stringify(newTimesheets)))
          this.setTimesheetExists(cloneDeep(newTimesheets))

          if (newTimesheets.length === 1) {
            newTimesheets[0].toSave = true
            this.timesheetDetailLocal = cloneDeep({
              ...this.timesheetDetailLocal,
              ...newTimesheets[0],
            })
            if (this.isNewEntry === null) {
              this.addCellTimesheetDetail({
                jobId: this.currentCellJobId,
                details: {
                  ...this.timesheetDetailLocal,
                  reportedDate: this.day.date,
                },
              })
            }
          }
          this.isNewEntry = false
        } else {
          const value = this.timesheetCellDetails.get(this.currentCellJobId)
          if (value) {
            this.setTimesheetDetail({
              data: cloneDeep(value),
              openDrawer: this.isTimeEntryDrawerOpen,
            })
          } else {
            this.addNewTimesheet({ showDrawer: this.isTimeEntryDrawerOpen })
          }

          this.timesheetDetailLocal = cloneDeep({
            ...this.timesheetDetail,
            ...omit(this.timesheetDetailLocal, [
              'location',
              'locationCode',
              'locationLabel',
              'countryCode',
              'wfh',
            ]),
          })
          if (this.isNewEntry === null) {
            this.addCellTimesheetDetail({
              jobId: this.currentCellJobId,
              details: {
                ...this.timesheetDetailLocal,
                reportedDate: this.day.date,
              },
            })
          }
          this.isNewEntry = true
        }
      }
    },
    cellAriaLabel() {
      if (this.isFutureDate)
        return this.$t(
          'timesheetUI.ValidationMessages.TimesheetEntryFutureNotAllowedMessage'
        )
      return this.cellValueAndLabel(this.day).value
    },
    resetDurationValues(resetError = true) {
      this.duration = this.cellValueAndLabel(this.day).value
      this.isAsyncValidationInProgress = false

      if (this.timesheetCellDetails.has(this.currentCellJobId)) {
        this.addCellTimesheetDetail({
          jobId: this.currentCellJobId,
          details: {
            ...this.timesheetDetailLocal,
            ...this.timesheetCellDetails.get(this.currentCellJobId),
            [this.getDurationKey()]: this.duration,
          },
        })

        this.timesheetDetailLocal[this.getDurationKey()] = this.duration

        if (resetError) this.error = ''
      }
    },

    isMultipleEntry() {
      return (
        getTimesheets(this.timesheets, this.timesheetJob.id, this.day.date)
          .length > 1 || false
      )
    },
  },

  created() {
    this.duration = this.cellValueAndLabel(this.day).value
    this.debouncedSave = debounce(async () => {
      // if (isNaN(this.debounce)) return
      try {
        this.isTimesheetSaveInProgress = true

        if (this.isNewEntry !== null && this.isNewEntry === false) {
          this.timesheetDetailLocal.toSave = true
          this.setCommentValue(this.timesheetDetailLocal.comment)
        }

        const payload = {
          timesheets: [this.timesheetDetailLocal],
          // timesheets: [
          //   { ...this.timesheetCellDetails.get(this.currentCellJobId) },
          // ],
          isInline: true,
        }

        const data = await this.saveTimesheet(payload)

        data.billableIndicator = getBillableIndicatorName(
          data.billableIndicator
        )

        this.shouldGlow = true

        if (
          this.isNewEntry &&
          !this.timesheetCellDetails.get(this.currentCellJobId).id
        ) {
          this.addCellTimesheetDetail({
            jobId: `${data.userJobId}--${data.reportedDate.replace('Z', '')}`,
            details: { ...data },
          })

          this.timesheetDetailLocal = { ...data }
          this.isNewEntry = false
          if (
            this.timesheetSelectedJob.date === this.day.date &&
            this.timesheetSelectedJob.job.id === this.timesheetJob.id
          ) {
            this.setTimesheetDetail({
              data: null,
              openDrawer: this.isTimeEntryDrawerOpen,
            })

            this.setTimesheetExists([{ ...data }])
          }
          this.setTimesheets([...this.timesheets, { ...data }])
          // this.setTimesheetExists([{ ...data }])
          this.setUnfilteredTimesheets([
            ...this.unfilteredTimesheets,
            { ...data },
          ])
        } else {
          const isZeroingOut = data[this.getDurationKey()] === 0

          const locationWfhSettings = this.getLocationWfhSettings(
            this.userSettings,
            data.reportedDate.replace('Z', ''),
            this.locationList
          )
          if (
            this.day.date === data.reportedDate.replace('Z', '') &&
            this.timesheetJob.id === data.userJobId
          ) {
            this.addCellTimesheetDetail({
              jobId: `${data.userJobId}--${data.reportedDate.replace('Z', '')}`,
              details: isZeroingOut
                ? {
                    ...this.newTimesheetObj,
                    reportedDate: this.day.date,
                    ...locationWfhSettings,
                  }
                : { ...data },
            })

            this.timesheetDetailLocal = isZeroingOut
              ? {
                  ...this.newTimesheetObj,
                  reportedDate: this.day.date,
                  // userJobId: this.timesheetJob.id,
                  ...locationWfhSettings,
                }
              : { ...data }
          }

          if (
            this.timesheetSelectedJob.date === this.day.date &&
            this.timesheetSelectedJob.job.id === this.timesheetJob.id
          ) {
            this.setTimesheetExists(isZeroingOut ? [] : [{ ...data }])
          }
          this.isNewEntry = isZeroingOut

          const updatedTimesheets = this.timesheets.filter((timesheet) => {
            if (timesheet.id != data.id) {
              return true
            }
          })
          const updatedUnFilteredTimesheets = this.unfilteredTimesheets.filter(
            (timesheet) => {
              if (timesheet.id != data.id) {
                return true
              }
            }
          )

          if (!isZeroingOut) {
            updatedTimesheets.push({ ...data })
            updatedUnFilteredTimesheets.push({ ...data })
          }

          this.setTimesheets(updatedTimesheets)
          this.setUnfilteredTimesheets(updatedUnFilteredTimesheets)
        }

        setTimeout(() => {
          this.shouldGlow = false
        }, 2000)
      } catch (error) {
        this.error = error.response?.data.message
      } finally {
        this.isTimesheetSaveInProgress = false
        this.clearCommentValue()
      }
    }, 0)

    this.debouncedHandleTimesheetDetailsLocalUpdateAndAsyncValidation =
      debounce(async () => {
        if (this.hasBlurTriggered) return

        this.handleTimesheetDetailsLocalUpdateAndAsyncValidation()
      }, 1500)

    EventBus.$on(
      'copyRestOfWeekStarted',
      ({ jobId, date, isSaving, offAll }) => {
        if (offAll) {
          this.isTimesheetSaveInProgress = false
        }
        const cellIsBeingCopied = this.currentCellJobId === `${jobId}--${date}`
        if (cellIsBeingCopied) {
          this.isTimesheetSaveInProgress = isSaving
        }
      }
    )
  },
  watch: {
    error() {
      if (this.error) {
        this.setTimeEntryError({
          msg: this.error,
          date: this.day.date,
          jobId: this.jobId,
        })
      }
    },
    updateCellValuesAfterTimsheetUpdateFromDrawer() {
      if (
        this.isInlineErrorResolved[this.currentCellJobId]?.isResolved === false
      )
        return

      this.duration = this.cellValueAndLabel(this.day).value

      if (this.isNewEntry === null) return

      // this.timesheetDetailLocal[this.getDurationKey()] = this.duration
      // this.addCellTimesheetDetail({
      //   jobId: this.currentCellJobId,
      //   details: { ...this.timesheetDetailLocal },
      // })

      if (
        this.duration == 0 &&
        this.timesheetSelectedJob.date == this.day.date &&
        this.timesheetSelectedJob.job.id == this.timesheetJob.id
      ) {
        this.isNewEntry = true
        this.addNewTimesheet({ showDrawer: this.isTimeEntryDrawerOpen })
        this.timesheetDetailLocal = cloneDeep({
          ...this.newTimesheetObj,
          reportedDate: this.day.date,
          // userJobId: this.timesheetJob.id,
          dayIndex: moment(this.day.date.split('T')[0]).isoWeekday() - 1,
        })

        this.addCellTimesheetDetail({
          jobId: this.currentCellJobId,
          details: {
            ...this.newTimesheetObj,
            reportedDate: this.day.date,
            // userJobId: this.timesheetJob.id,
            dayIndex: moment(this.day.date.split('T')[0]).isoWeekday() - 1,
          },
        })
      } else {
        // this.isNewEntry = false
      }
    },
    isTimeEntryDrawerOpen(isOpen) {
      if (
        isOpen ||
        this.isTimesheetSaveInProgress ||
        this.isAsyncValidationInProgress
      )
        return

      if (
        !isOpen &&
        this.timesheetSelectedJob.date === this.day.date &&
        this.timesheetSelectedJob.job.id === this.timesheetJob.id
      ) {
        this.resetDurationValues()
      }
    },

    timesheetDetailDurationUpdated(durationExists) {
      if (
        !durationExists ||
        !this.isTimeEntryDrawerOpen ||
        this.timesheetSelectedJob.date !== this.day.date ||
        this.timesheetSelectedJob.job.id !== this.timesheetJob.id
      ) {
        return
      }

      if (this.isNewEntry) {
        this.duration = this.timesheetDetail?.[this.getDurationKey()] || 0

        return
      }

      if (!this.isNewEntry) {
        let durationLocal = 0

        if (this.timesheetDetail.id && this.timesheetExists.length === 1) {
          durationLocal = this.timesheetDetail[this.getDurationKey()] || 0
        }

        if (this.timesheetDetail.id && this.timesheetExists.length > 1) {
          const currentSheet = this.timesheets.find(
            (t) => t.id === this.timesheetDetail.id
          )

          durationLocal =
            this.cellValueAndLabel(this.day).value -
            currentSheet[this.getDurationKey()] +
            parseFloat(this.timesheetDetail[this.getDurationKey()] || 0)
        }

        if (this.timesheetDetail && !this.timesheetDetail.id) {
          durationLocal =
            parseFloat(this.cellValueAndLabel(this.day).value) +
            (parseFloat(this.timesheetDetail?.[this.getDurationKey()]) || 0)
        }

        this.duration = durationLocal
      }
    },

    activeCell(newVal, oldVal) {
      if (newVal !== oldVal && this.isTimeEntryDrawerOpen && !this.error) {
        const date = oldVal.slice(-8)
        const jobId = oldVal.slice(0, -8)

        if (
          this.timesheetSelectedJob.date.split('T')[0].replaceAll('-', '') ==
            date &&
          this.timesheetSelectedJob.job.id == jobId
        )
          this.resetDurationValues()
      }
    },

    isNewEntryBeingCreatedOnADayWithExistingTimesheet(isChanged) {
      this.isDrawerOpenFroCurrentCell &&
        isChanged &&
        this.resetDurationValues(false)
    },

    isInlineErrorResolved: {
      handler() {
        if (
          this.isInlineErrorResolved[
            `${this.timesheetJob.id}--${this.day.date}`
          ]?.isResolved ||
          !(
            `${this.timesheetJob.id}--${this.day.date}` in
            this.isInlineErrorResolved
          )
        ) {
          this.error = null
          this.isDrawerError = false
        }
      },
      deep: true,
    },

    preparingForAutoSave: {
      deep: true,
      handler(newVal) {
        if (!newVal) {
          this.isTimesheetSaveInProgress = false
          return
        }
        const id = `${this.timesheetJob.id}--${this.day.date}`
        this.isTimesheetSaveInProgress = id in this.preparingForAutoSave
      },
    },

    updateCellStateAfterTimsheetUpdateFromLocation(newVal) {
      if (newVal.includes(this.currentCellJobId)) {
        this.handleInputFocus()
        this.removeFromUpdateCellStateAfterTimsheetUpdateFromLocation(
          this.currentCellJobId
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
::v-deep {
  .job-group__day--rejected {
    .time-entry--cell {
      background-color: $danger;
    }
  }
}
</style>
