<template>
  <div>
    <div aria-live="assertive" class="sr-only" v-text="liveRegionText"></div>
    <div ref="errorContainer"></div>
    <v-row>
      <v-col :cols="isTimeEntryDrawerExpanded ? 6 : 12" class="pb-0">
        <!-- Percentage User Duration -->
        <div
          v-if="
            timesheetDetailFeatures.percentageOrDuration.data.isPercentageAgency
          "
        >
          <v-label
            v-if="
              timesheetDetailFeatures.percentageOrDuration.data
                .isPercentageAgency
            "
            for="durationInput"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryPercentLabel') }}
            <span class="required">*</span></v-label
          >
          <v-label v-else for="durationInput"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryDuration') }}
            <span class="required">*</span></v-label
          >

          <validation-provider
            v-slot="{ errors }"
            rules="required|durationValidation"
            name="DurationField"
          >
            <v-text-field
              id="durationInput"
              outlined
              dense
              autofocus
              placeholder="Duration"
              ref="timeEntryInput"
              v-model="timesheetDetail.durationPercentageValue"
              :error-messages="errors"
              aria-describedby="durationInputError"
              @focus="handleDurationFocus(errors)"
            >
              <template v-slot:message="{ message }">
                <div aria-live="polite" id="durationInputError">
                  {{ message }}
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </div>

        <!-- Non Percentage User Duration -->
        <div v-else>
          <v-label for="durationInput"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryDuration') }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required|durationValidation"
            name="DurationField"
          >
            <v-text-field
              id="durationInput"
              outlined
              aria-required="true"
              dense
              autofocus
              ref="timeEntryInput"
              placeholder="Duration"
              v-model="timesheetDetail.durationValue"
              :error-messages="errors"
              aria-describedby="durationInputError"
              @focus="handleDurationFocus(errors)"
            >
              <template v-slot:message="{ message }">
                <div aria-live="polite" id="durationInputError">
                  {{ message }}
                </div>
              </template>
            </v-text-field>
          </validation-provider>
        </div>

        <!-- WorkCode -->
        <div
          v-if="!isHideTimesheetWorkcode"
          id="workCodeInput-container"
          class="attached-dropdown"
        >
          <v-label for="workCodeInput"
            >{{
              $t('timesheetUI.EntryFormLabels.TimesheetEntryLabelWorkcode')
            }}
            <span class="required">*</span></v-label
          >
          <v-text-field
            id="workCodeInput"
            v-if="
              selectedPPMTask.jobTitleForBilling ||
              timesheetDetail.disablePPMSelect ||
              (agencyData.isRigidAgency &&
                timesheetDetailFeatures.workcode.data.workCodes.length === 1)
            "
            :value="computedWorkCodeText"
            outlined
            dense
            disabled
            aria-describedby="workCodeInputError"
          >
            <template v-slot:message="{ message }">
              <div aria-live="polite" id="workCodeInputError">
                {{ message }}
              </div>
            </template>
          </v-text-field>

          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Workcode"
            :immediate="!!Object.keys(this.isInlineErrorResolved).length"
            v-else
          >
            <v-autocomplete
              role="listbox"
              id="workCodeInput"
              ref="workCodeInput"
              attach="#workCodeInput-container"
              :menu-props="{
                nudgeBottom: 28,
                offsetY: true,
                allowOverflow: true,
              }"
              outlined
              dense
              v-model="timesheetDetail.workCode"
              @change="loadBillableIndicators(timesheetDetail.workCode)"
              :items="timesheetDetailFeatures.workcode.data.workCodes"
              :error-messages="errors"
              item-text="label"
              item-value="value"
              placeholder="Workcode"
              :disabled="disableWorkCode"
              @keydown="arrowKeyHandler"
              clearable
              @focus="announceError(errors)"
            ></v-autocomplete>
          </validation-provider>
        </div>
      </v-col>
      <v-col
        :cols="isTimeEntryDrawerExpanded ? 6 : 12"
        :class="['pb-0', { 'pt-0': !isTimeEntryDrawerExpanded }]"
      >
        <!-- Billable Indicator -->
        <div id="billableIndicator-container" class="attached-dropdown">
          <v-label for="billableIndicator"
            >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryLabelBI') }}
            <span class="required">*</span></v-label
          >
          <validation-provider
            v-slot="{ errors }"
            rules="required"
            name="Billable Indicator"
          >
            <v-combobox
              role="listbox"
              id="billableIndicator"
              ref="billableIndicator"
              attach="#billableIndicator-container"
              :menu-props="{
                offsetY: true,
                nudgeBottom: 28,
              }"
              aria-required="true"
              outlined
              dense
              :items="timesheetDetailBillableIndicators"
              v-model="timesheetDetail.billableIndicator"
              item-text="label"
              placeholder="Billable Indicator"
              :error-messages="errors"
              :loading="loadingBI"
              return-object
              :disabled="
                isTimesheetApproved ||
                timesheetDetailBillableIndicators.length <= 1 ||
                timesheetDetail.disablePPMSelect ||
                disablePPMWorkcode
              "
              @keydown="arrowKeyHandler"
              @focus="announceError(errors)"
            ></v-combobox>
          </validation-provider>
        </div>

        <!-- Comment -->
        <div>
          <CommentField />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { announceErrorHandler } from '@Shared/announceError'
import CommentField from './CommentField.vue'
import {
  arrowKeyHandler as arrowKeyHandlerClick,
  handleArrowKeys,
} from '@Shared/filters'
import { mapActions, mapGetters, mapState } from 'vuex'
import i18n from '@Shared/i18n'
import { debounce } from 'lodash'

export default {
  components: {
    ValidationProvider,
    CommentField,
  },
  data() {
    return {
      liveRegionText: '',
    }
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetDetail',
      'timesheetDetailBillableIndicators',
      'loadingBI',
      'isTimeEntryDrawerExpanded',
      'timesheetDetailFeatures',
      'agencyData',
      'timesheetSelectedJob',
      'selectedPPMTask',
      'isInlineErrorResolved',
    ]),
    ...mapGetters('storeTimesheets', [
      'isHideTimesheetWorkcode',
      'isTimesheetApproved',
      'disableWorkCode',
    ]),
    computedWorkCodeText() {
      if (this.timesheetDetail.jobTitleForBilling) {
        return `${this.timesheetDetail.jobTitleForBilling} - ${this.timesheetDetail.jobTitleForBillingName}`
      }

      if (this.timesheetDetail.workCode) {
        return `${this.timesheetDetail.workCode} - ${this.timesheetDetail.workCodeName}`
      }

      return i18n.t(
        'timesheetUI.EntryFormLabels.TimesheetEntryLabelWorkcode',
        window.CULTURE
      )
    },
    disablePPMWorkcode: {
      cache: false,
      get() {
        if (
          this.timesheetSelectedJob?.job?.isClarizenTask ||
          this.timesheetSelectedJob?.job?.isClarizenAssignedProject ||
          this.timesheetSelectedJob?.job?.selfAssignmentWithManageTasking
        ) {
          return true
        }
        return false
      },
    },
  },
  methods: {
    ...mapActions('storeTimesheets', [
      'loadValidationLogic',
      'loadBillableIndicators',
      'loadWorkcodeandBillableIndicators',
    ]),
    handleDurationFocus(errors) {
      this.$refs.timeEntryInput.$el.querySelector('input').select()
      this.$emit('focus')
      this.announceError(errors)
    },
    announceError(errors) {
      announceErrorHandler(
        errors?.length && errors[0],
        this.$refs.errorContainer
      )
    },
    arrowKeyHandler: debounce(function ($e) {
      arrowKeyHandlerClick($e)

      handleArrowKeys(this, $e, ['workCodeInput', 'billableIndicator'])
    }, 300),
  },
  mounted() {
    this.loadValidationLogic()
    this.loadWorkcodeandBillableIndicators()
  },
}
</script>

<style lang="scss" scoped>
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.attached-dropdown::v-deep {
  position: relative;
}
</style>
