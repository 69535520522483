<template>
  <VNavigationDrawer
    v-if="isTimeEntryDrawerVisible"
    app
    right
    floating
    :style="`width: ${timeEntryDrawerCSSWidth}`"
    class="time-entry-drawer"
    v-model="isTimeEntryDrawerVisible"
    disable-resize-watcher
    :temporary="isTimeEntryDrawerUnfolded"
    permanent
  >
    <!-- permanent -->
    <FocusLock returnFocus>
      <div data-no-autofocus>
        <validation-observer ref="observer" slim>
          <VForm
            :key="isLazyValidation"
            class="d-flex flex-column flex-grow-1"
            @submit.prevent="saveValidate"
            :lazy-validation="isLazyValidation"
            :disabled="
              (isTimesheetApproved && !isTimesheetBeingCorrected) ||
              (timesheetDetail && timesheetDetail.selfCorrectedHours < 0)
            "
          >
            <div
              class="time-entry-drawer__wrapper d-flex flex-column"
              data-no-focus-lock
            >
              <!-- header -->
              <div class="time-entry-drawer__header">
                <time-entry-drawer-header
                  class="px-6 py-4"
                  :handleClose="handleClose"
                />
                <VDivider role="presentation" />

                <VProgressLinear
                  indeterminate
                  v-if="loadingFeatures"
                  color="primary"
                />
              </div>

              <!-- Body -->
              <div
                class="flex-grow-1 d-flex flex-column"
                id="navigation-drawer-body"
              >
                <div class="px-6 py-4 timesheet-entry__body flex-grow-1">
                  <div class="timesheet-entry__form">
                    <div v-if="showForm">
                      <div class="mb-4" v-if="!timesheetDetail.id">
                        <div
                          class="mb-2 accent--text font-size--14 font-weight--600 mr-5"
                        >
                          {{ $t('General.Btns.NewEntry') }}
                        </div>
                      </div>
                      <div
                        v-else-if="
                          !isTimesheetBeingReviewed &&
                          !isTemporaryExternalEmployee
                        "
                        class="text-right"
                        :style="{
                          marginBottom: '-16px',
                        }"
                      >
                        <div
                          :class="`d-inline-block status-pill status-pill--${timesheetDetail?.timesheetStatus?.toLowerCase()}`"
                        >
                          <span
                            v-if="
                              timesheetDetail?.timesheetStatus === 'Unsubmitted'
                            "
                            >{{ $t('timesheetUI.Status.Unsubmitted') }}</span
                          >
                          <span
                            v-if="
                              timesheetDetail?.timesheetStatus === 'Submitted'
                            "
                            >{{ $t('timesheetUI.Status.Submitted') }}</span
                          >
                          <span
                            v-if="
                              timesheetDetail?.timesheetStatus === 'Approved'
                            "
                            >{{ $t('timesheetUI.Status.Approved') }}</span
                          >
                          <span
                            v-if="
                              timesheetDetail?.timesheetStatus === 'Rejected'
                            "
                            >{{ $t('timesheetUI.Status.Rejected') }}</span
                          >
                        </div>
                      </div>
                      <time-entry-skeleton-loader v-if="loading" />
                      <timesheet-entry-detail
                        :key="
                          preferenceDialogOpen
                            ? `${timesheetDetail?.locationCode}-${timesheetDetail?.wfh}-${timesheetDetail.id}`
                            : timesheetDetail.id
                        "
                      ></timesheet-entry-detail>
                    </div>

                    <timesheet-entry-table v-else></timesheet-entry-table>
                  </div>
                </div>
              </div>

              <!-- Footer -->
              <div class="time-entry-drawer__footer">
                <v-divider role="presentation"></v-divider>
                <div class="px-3 py-4">
                  <time-entry-drawer-footer :saveValidate="saveValidate" />
                </div>
              </div>
            </div>
          </VForm>
        </validation-observer>
        <v-btn
          icon
          outlined
          color="primary"
          width="24"
          height="24"
          class="time-entry-drawer--expand-btn"
          @click="toggleTimeEntryDrawerExpansion"
          tabindex="0"
          :aria-label="
            isTimeEntryDrawerUnfolded
              ? 'view form in slim view'
              : 'view form in large view'
          "
        >
          <v-icon size="17" v-if="!isTimeEntryDrawerUnfolded"
            >mdi-chevron-left</v-icon
          >
          <v-icon size="17" v-if="isTimeEntryDrawerUnfolded"
            >mdi-chevron-right</v-icon
          >
        </v-btn>
      </div>
    </FocusLock>
  </VNavigationDrawer>
</template>

<script>
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex'
import TimeEntryDrawerHeader from './TimeEntryDrawerHeader.vue'
import TimeEntryDrawerFooter from './TimeEntryDrawerFooter.vue'
import TimeEntrySkeletonLoader from './TimeEntrySkeletonLoader.vue'
import TimesheetEntryTable from '../time-entry/TimesheetEntryTable.vue'
import TimesheetEntryDetail from '../time-entry/TimesheetEntryDetail.vue'

import FocusLock from 'vue-focus-lock'
import { ValidationObserver } from 'vee-validate'
import cloneDeep from 'lodash/cloneDeep'
import { isEmpty, pickBy } from 'lodash'
import moment from 'moment'
import { formatDateFrontend } from '@Shared/filters'
import InlineToast from '@Components/inline-toast/InlineToast.vue'

export default {
  name: 'TimeEntryDrawer',
  components: {
    FocusLock,
    ValidationObserver,
    TimeEntryDrawerHeader,
    TimesheetEntryTable,
    TimesheetEntryDetail,
    TimeEntryDrawerFooter,
    TimeEntrySkeletonLoader,
  },
  data() {
    return {
      somethingSaved: false,
      timesheetExistsClone: {},
      loading: true,
      // alertConfirmDialogOpen: false,
    }
  },
  watch: {
    loadingFeatures(val) {
      this.loading = val

      if (
        !this.loading &&
        this.isInlineErrorResolved[
          `${this.timesheetDetail?.userJobId}--${this.timesheetDetail?.reportedDate}`
        ] === false
      ) {
        this.$refs.observer.validate()
      }
    },
    isTimeEntryDrawerOpen() {
      if (this.isTimeEntryDrawerOpen) {
        this.setBeforeEdit(JSON.parse(JSON.stringify(this.timesheetExists)))
        this.timesheetExistsClone = cloneDeep(this.timesheetExists)

        this.loadJobFeatures()
        window.addEventListener('keydown', this.handleEscape)
      } else {
        this.handleHideDrawer()
        window.removeEventListener('keydown', this.handleEscape)
      }
      if (
        this.timesheetExists.length < 2 &&
        this.timesheetSelectedJob.date ===
          this.timesheetExists[0].reportedDate.replace('Z', '')
      ) {
        this.setTimesheetDetail({
          data: this.timesheetExists[0],
          openDrawer: true,
        })
      }

      if (
        this.timesheetExists.length > 1 &&
        this.timesheetSelectedJob.date ===
          this.timesheetExists[0].reportedDate.replace('Z', '')
      ) {
        this.setTimesheetDetail({
          data: null,
          openDrawer: true,
        })
      }
    },
    timesheetExists: {
      deep: true,
      handler() {
        if (!this.isTimeEntryDrawerOpen || this.isTimesheetBeingCorrected)
          return

        // ignore for new entry
        if (this.timesheetDetail && !this.timesheetDetail.id) return

        //if there is only single timesheet then show form with details
        if (this.timesheetExists.length < 2) {
          this.setTimesheetDetail({
            data: this.timesheetExists[0],
            openDrawer: true,
          })
        }
      },
    },
    //handle Case where location for saved timesheet is different then timesheetFeatures locations
    timesheetDetail: {
      deep: true,
      handler(oldVal, newVal) {
        if (!this.timesheetDetail) return
        if (oldVal?.reportedDate === newVal?.reportedDate) return

        // if (this.isAllowPTOWorkCode && this.timesheetDetail.id) {
        //   this.getAvailableFeaturesByLocation(this.timesheetDetail.location)
        // }

        this.setHasTimeEntryChanged({ e: oldVal && newVal, data: null })

        if (
          !this.timesheetDetailFeatures ||
          !this.timesheetDetailFeatures.location.required
        )
          return

        if (this.timesheetDetail && !this.timesheetDetail.location) {
          const found =
            this.timesheetDetailFeatures.location.data.locations.find(
              (a) => a.selected
            )

          if (found) {
            this.timesheetDetail.location = found.label
            this.timesheetDetail.locationCode = found.value
          }
        }

        if (
          !this.timesheetDetailFeatures.location.data.locations.some(
            (a) => a.value === this.timesheetDetail.locationCode
          ) &&
          this.timesheetDetail.timesheetStatus === 'Approved'
        ) {
          this.timesheetDetailFeatures.location.data.locations = [
            {
              label: this.timesheetDetail.location,
              value: this.timesheetDetail.locationCode,
            },
          ]
        }
        if (this.timesheetDetail.locationCode) {
          const capturedLocation =
            this.timesheetDetailFeatures.location.data.locations.find(
              (location) => location.value === this.timesheetDetail.locationCode
            )
          this.timesheetDetail.locationLabel = capturedLocation?.label
          this.timesheetDetail.countryCode = capturedLocation?.countryCode
        }
      },
    },
    timesheetSelectedJob: {
      handler(currJob, oldJob) {
        if (oldJob.date === currJob.date && currJob.job.id === oldJob.job.id)
          return
        if (this.isTimeEntryDrawerOpen) {
          this.setActiveCell(
            `${(currJob.job.id + currJob.date.split('T')[0]).replaceAll(
              '-',
              ''
            )}`
          )
          this.loadJobFeatures()
        }
      },
    },
  },
  computed: {
    ...mapState('storeTimesheets', [
      'timesheetExists',
      'timesheetSelectedJob',
      'timesheetDetail',
      'beforeEdit',
      'isTimeEntryDrawerOpen',
      'isTimeEntryDrawerExpanded',
      'loadingFeatures',
      'observer',
      'timesheetDetailFeatures',
      'userSettings',
      'isTimesheetBeingCorrected',
      'isTimesheetBeingReviewed',
      'isInlineErrorResolved',
      'preferenceDialogOpen',
      'activeCell',
      'timeEntryError',
      'copyRestOfWeekSelected',
      'calendar',
      'allowCopyRestOfWeek',
      'agencyData',
      'pendingUserLocationSettings',
      'pendingUserWFHSettings',
    ]),
    ...mapGetters('storeTimesheets', [
      'showForm',
      'isTimesheetApproved',
      'isAllowPTOWorkCode',
      'timeEntryDrawerCSSWidth',
      'isTemporaryExternalEmployee',
      'mergedPendingLocationAndWFHSettings',
    ]),
    ...mapGetters('storeAbsenceTracker', ['getAbsenceTotalByDay']),
    isLazyValidation() {
      const errors = pickBy(this.isInlineErrorResolved, (v) => !v?.isResolved)
      return isEmpty(errors)
    },
    isTimeEntryDrawerVisible: {
      get() {
        return this.isTimeEntryDrawerOpen
      },

      set(newVal) {
        newVal ? this.showTimeEntryDrawer() : this.hideTimeEntryDrawer()
      },
    },
    isTimeEntryDrawerUnfolded: {
      get() {
        return this.isTimeEntryDrawerExpanded
      },
    },
    hasErrorMessage() {
      return this.timeEntryError.filter((a) => {
        return (
          moment(a.date).isSame(this.timesheetSelectedJob.date) &&
          a.jobId === this.timesheetSelectedJob.job.id
        )
      })
    },
    showCopyCheckbox() {
      if (
        this.timesheetDetail?.id ||
        this.timesheetDetail?.timesheetStatus === 'Approved' ||
        this.timesheetDetail?.timesheetStatus === 'Rejected'
      ) {
        return false
      }

      const calendarDay = this.calendar[this.timesheetDetail.dayIndex]

      // const isFridayOrLater = this.timesheetDetail.dayIndex >= 4
      const isFridayOrLater =
        moment(this.timesheetDetail.reportedDate.split('T')[0]).isoWeekday() -
          1 >=
        4

      const checkAbsenceModule = () => {
        if (this.agencyData.enableAbsenceModule) {
          const foundHoliday = this.getAbsenceTotalByDay.find((a) =>
            moment(calendarDay.date.split('T')[0]).isSame(a.date.split('T')[0])
          )

          return foundHoliday
        }

        if (!this.agencyData.enableAbsenceModule) {
          return calendarDay.isHoliday
        }
      }

      if (
        !this.allowCopyRestOfWeek ||
        calendarDay.isWeekend ||
        checkAbsenceModule() ||
        isFridayOrLater
      ) {
        return false
      }

      const dayIndex = this.timesheetDetail?.dayIndex

      const durationValueRules = () => {
        if (this.timesheetDetail?.id) {
          return this.timesheetDetail?.isPercentageEntry
            ? parseFloat(this.timesheetDetail?.durationPercentageValue) !== 0
            : parseFloat(this.timesheetDetail?.durationValue) !== 0
        }

        return true
      }

      return (
        !moment(this.calendar[dayIndex].date).isSame(new Date(), 'day') &&
        durationValueRules &&
        (!this.calendar[dayIndex].isHoliday ||
          !this.calendar[dayIndex].isWeekend)
      )
    },
  },
  updated() {
    this.setObserver(this.$refs.observer)

    if (
      this.hasErrorMessage &&
      this.isTimeEntryDrawerVisible &&
      this.isInlineErrorResolved[
        `${this.timesheetDetail?.userJobId}--${this.timesheetDetail?.reportedDate}`
      ]?.isResolved === false
    ) {
      setTimeout(() => {
        if (this.timeEntryError.length > 0 && this.isTimeEntryDrawerVisible) {
          this.$refs.observer.validate()
        }
        this.$refs?.observer?.validate()
        if (this.timeEntryError.length > 0 && this.isTimeEntryDrawerVisible) {
          this.$refs.observer.validate()
        }
      }, 1000)
    }
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'showTimeEntryDrawer',
      'hideTimeEntryDrawer',
      'expandTimeEntryDrawer',
      'collapseTimeEntryDrawer',
      'setObserver',
      'resetTableToSave',
      'revertTimesheet',
      'setTimesheetDetail',
      'setTimesheetExists',
      'setIsTimesheetBeingCorrected',
      'setIsTimesheetBeingReviewed',
      'setTimesheetDetailOld',
      'setBeforeEdit',
      'setInlineErrorStatus',
      'setHasTimeEntryChanged',
      'setActiveCell',
      'removeTimeEntryError',
      'setCopyRestOfWeekSelected',
    ]),
    ...mapActions('storeTimesheets', [
      'saveTimesheet',
      'loadJobFeatures',
      'getAvailableFeaturesByLocation',
      'handlePendingInlineValidationAndSave',
      'saveUserSettings',
    ]),
    toggleTimeEntryDrawerExpansion() {
      this.isTimeEntryDrawerExpanded
        ? this.collapseTimeEntryDrawer()
        : this.expandTimeEntryDrawer()
    },
    handleValidationAndSave(payload) {
      return this.$refs.observer.validate().then((valid) => {
        if (valid) {
          this.somethingSaved = true

          return this.saveTimesheet(payload)
            .then(() => {
              this.somethingSaved = false
              // create a timesheetExists clone to figure out a modification in the form to enable/disable the save button
              this.timesheetExistsClone = cloneDeep(this.timesheetExists)

              if (this.timesheetExistsClone.length > 0) {
                this.timesheetExistsClone.forEach((obj) => delete obj.toSave)
              }
            })
            .catch(() => {
              console.log('error in drawer')
            })
        }
      })
    },
    async saveValidate(payload) {
      console.log(this.timesheetSelectedJob)
      if (this.agencyData.isLocationActive && !this.copyRestOfWeekSelected) {
        if (this.pendingUserLocationSettings || this.pendingUserWFHSettings) {
          await this.saveUserSettings(this.mergedPendingLocationAndWFHSettings)
        }

        return this.handlePendingInlineValidationAndSave()
          .then(() => {
            this.setInlineErrorStatus({
              jobId: `${this.timesheetSelectedJob.job.id}--${this.timesheetSelectedJob.date}`,
              isResolved: true,
              type: 'validation',
            })
            return this.handleValidationAndSave(payload)
          })
          .catch(() => {
            this.$toast.error({
              component: InlineToast,
              props: {
                message: this.$t(
                  'timesheetUI.ValidationMessages.TimesheetUnknownError',
                  {
                    date: formatDateFrontend(this.timesheetDetail.reportedDate),
                    jobName: this.timesheetSelectedJob.job.jobName,
                    jobId: this.timesheetSelectedJob.job.job_ID,
                  }
                ),
              },
            })
            return
          })
      } else {
        return this.handleValidationAndSave(payload)
      }
    },
    handleClose() {
      // if (this.observer.flags.dirty) {
      //   // EventBus.$emit('dialogAlertConfirmShow', {
      //   //   dialogAlertType: 'warning',
      //   //   dialogAlertTitle: i18n.t(
      //   //     'General.Alerts.UnSavedChanges',
      //   //     window.CULTURE
      //   //   ),
      //   //   dialogAlertMessage: i18n.t(
      //   //     'General.Alerts.UnSavedChangesMessage',
      //   //     window.CULTURE
      //   //   ),
      //   //   dialogCallback: this.handleHideDrawer,
      //   //   dialogConfirmLabel: i18n.t('General.Btns.Leave', window.CULTURE),
      //   //   dialogCancelLabel: i18n.t('General.Btns.Stay', window.CULTURE),
      //   // })
      // } else {
      // }
      this.handleHideDrawer()
    },
    handleHideDrawer() {
      this.setCopyRestOfWeekSelected(false)
      this.resetTableToSave()
      if (!this.somethingSaved) {
        this.revertTimesheet(this.beforeEdit)
      }

      this.somethingSaved = false
      this.hideTimeEntryDrawer()
      this.$refs.observer.reset()
      this.setTimesheetDetail({ data: null, openDrawer: false })
      this.setTimesheetExists(false)
      this.setIsTimesheetBeingCorrected(false)
      this.setIsTimesheetBeingReviewed(false)
      this.setTimesheetDetailOld(null)
    },
    // onClickOutSide() {
    //   if (!this.isTimeEntryDrawerUnfolded) return
    //   if (!this.observer) return
    //   if (this.observer.flags.dirty) {
    //     EventBus.$emit('dialogAlertConfirmShow', {
    //       dialogAlertType: 'warning',
    //       dialogAlertTitle: i18n.t(
    //         'General.Alerts.UnSavedChanges',
    //         window.CULTURE
    //       ),
    //       dialogAlertMessage: i18n.t(
    //         'General.Alerts.UnSavedChangesMessage',
    //         window.CULTURE
    //       ),
    //       dialogCallback: this.hideTimeEntryDrawer,
    //       dialogConfirmLabel: i18n.t('General.Btns.Leave', window.CULTURE),
    //       dialogCancelLabel: i18n.t('General.Btns.Stay', window.CULTURE),
    //     })
    //   } else {
    //     if (this.isTimeEntryDrawerUnfolded) {
    //       this.hideTimeEntryDrawer()
    //     }
    //   }
    // },
    // include() {
    //   //include elements which opens up as dailog
    //   const includeElements = []
    //   if (document.querySelector('.dialog-alert--confirm-action')) {
    //     includeElements.push(
    //       document.querySelector('.dialog-alert--confirm-action')
    //     )
    //   }
    //   return includeElements
    // },
    handleEscape(event) {
      if (event.key === 'Escape' && this.isTimeEntryDrawerVisible) {
        this.isTimeEntryDrawerVisible = false
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.timesheet-entry__dialog-close {
  border: 1px solid;
}
.time-entry-drawer {
  box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.1);
  .time-entry-drawer__wrapper {
    min-height: 100vh;
    height: 100vh;
  }
}
.time-entry-drawer--expand-btn {
  border: thin solid $border-color;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.time-entry-drawer {
  overflow: unset;
}

#navigation-drawer-body {
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track-piece {
    background-color: #fff;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #cdcdcd;
    outline: 2px solid #fff;
    outline-offset: -2px;
    border: 0.1px solid #cdcdcd;
    border-radius: 20px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #8f8e8e;
  }
}
.v-input--selection-controls__input {
  margin-right: 0;
}

::v-deep {
  .custom-switch--color {
    &.v-input--is-label-active {
      .v-input--switch__thumb {
        background-color: #fff !important;
      }
      .v-input--switch__track {
        background-color: $accent !important;
        opacity: 1;
      }
    }
  }
}
</style>
