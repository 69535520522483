export default {
  5011: `Pas de données utilisateurs correspondantes`,
  5012: `Utilisateur ou contrat utilisateur Altair introuvable.`,
  5013: `Utilisateur ou contrat utilisateur Altair introuvable.`,
  5014: `Oups, vous ne pouvez plus charger de temps pour ce dossier, worcode et indicateur de facturation. Vous pouvez seulement remettre le nombre d'heures à 0.`,
  5015: `Oups, votre id de dossier a été changée. Veuillez remettre le nombre d'heures à 0, ajouter de nouveau le dossier avec le bon id de dossier et entrer votre temps.`,
  5016: `<b>Oups, </b> Votre code fonction (workcode) a changé. Veuillez modifier la durée à 0, ajouter le bon workcode et saisir vos heures à nouveau`,
  5017: `Ce dossier n'existe pas dans votre agence actuelle`,
  5018: `Désolé, Votre agence n'est pas encore active dans rOar à cette date.`,
  5019: `Désolé, La saisie des temps dans rOar n'est pas encore activée pour votre Agence.`,
  5020: `Désolé, Votre agence n'est pas encore active dans rOar à cette date.`,
  5021: `<b>Temps déjà approuvé</b><br/> Vous ne pouvez pas modifier les temps déjà approuvés.`,
  5024: `Oups, vous ne pouvez plus charger de temps sur cette tâche: {data} > {1}. Merci de contacter votre gestionnaire de ressources.`,
  5027: `Désolé. Le plafond d'heures est déjà atteint pour ce dossier. Merci de consulter les équipes en charge du suivi financier`,
  5028: `Vous ne pouvez charger de temps en Double overtime que sur les dimanches ou jours fériés.`,
  5029: `Oups, Vous ne pouvez plus saisir de temps sur ce dossier car vous avez changé d'agence. Veuillez modifier la durée à 0, ajouter un dossier de votre nouvelle agence et saisir vos heures.`,
  5030: `Vous ne pouvez pas charger de temps sur ce dossier, car il n'est pas ouvert pour charger des temps à cette date.`,
  5031: `Ce dossier n'est plus ouvert à la saisie des temps.`,
  5033: `Vous ne pouvez charger de temps sur des tâches regular ou flex que si vous ne dépassez pas votre nombre d'heures quotidiennes prévu, et si ce n'est pas un jour férié`,
  5036: `<p class=mb-1><b>Tout doux!</b></br>Ce dossier n'autorise pas la saisie des temps dans le futur.`,
  5037: `Désolé, Vous ne pouvez pas saisir de temps pour cette date. Si besoin, veuillez contacter l'équipe support locale.`,
  5038: `Désolé, Nous ne trouvons pas d'approbateur pour votre feuille de temps. Veuillez contacter l'équipe support locale.`,
  5039: `Vous ne pouvez charger de temps sur des tâches Overtime que pour les samedis ou lorsque vous dépassez votre nombre d'heures quotidienne prévue`,
  5040: `Vous ne pouvez soumettre de temps sur des tâches Overtime que pour les samedis ou lorsque vous dépassez votre nombre d'heures quotidienne prévue`,
  5041: `Vous pouvez saisir des temps à partir de {data}.`,
  5042: `Désolé, Votre agence n'est pas encore active dans rOar à cette date`,
  5010: `Vous ne pouvez pas soumettre les feuilles de temps saisies avant {data}.`,
  5043: `Les dossiers suivants ne sont plus ouverts à la saisie des temps : <br>`,
  5044: `Vous pouvez saisir des temps à partir de {data}.`,
  5046: `Saisir une date de début de validité`,
  5009: `<b>Attention,</b>Vous avez déjà sélectionné ce dossier`,
  5048: `Relax!</b>C'est le week-end. Votre agence n'autorise pas la saisie de temps le week-end.`,
  5004: `Ces feuilles de temps ont un nombre d'heures différents de 0. Veuillez remettre à 0 les temps sur le dossier ou rafraichir votre écran.`,
  5050: `Cette feuille de temps est déjà approuvée. Merci de rafraîchir votre écran.`,
  5049: `Le dossier n'est pas disponible pour cet utilisateur`,
  5047: `Ce dossier a déjà été ajouté à votre feuille de temps.`,
  5064: `Merci de demander à votre Directeur de Projet qu'il vous assigne à ce projet afin que vous puissiez charger vos temps. `,
  5065: `<h3 class='mt-2 mb-8 alert-title alert-title--error'>Nous n'avons trouvé aucun dossier à copier à partir de la semaine dernière</h3><br/><br/><p class=text-left><b>Les raisons possibles sont...</b><br/><ol class=text-left><li>Aucun temps n'était chargé sur les dossiers</li><li>Les dossiers ne sont plus ouverts pour charger des temps</li><li>Les tâches étaient assignées par votre directeur de projet (seulement utilisateurs rOar Project Management)</li></ol></p>`,
  5002: `Veuillez entrer la quantité`,
  5003: 'Veuillez entrer le lieu',
  5052: 'Dossier Utilisateur inconnu',
  5053: 'Règles Agence inconnues',
  5054: 'Workcode inconnu',
  5055: 'Pas de feuilles de temps à soumettre',
  5056: 'Numéro de Dossier Altair dupliqué',
  5057: `<h2 class='mt-2 mb-8 alert-title alert-title--error'>Oops</h2> <p>Vous ne pouvez pas charger plus de 100%</p>`,
  5061: `L'ID de la feuille de temps ne correspond pas avec l'ID du Dossier`,
  5062: `C'est les vacances. Vous ne pouvez pas charger de temps sur un jour férié.`,
  5063: `Vous ne pouvez plus charger de temps sur ce dossier car il n'est plus ouvert pour les temps à cette date.`,
  5066: `La règle n'est pas activée, par conséquent vous ne pouvez plus charger de temps sur ce dossier.`,
  5026: `Vous ne pouvez plus charger de temps sur ce dossier car il n'est plus ouvert pour les temps à cette date.`,
  5067: `Les personnes appartenant aux équipes support ne peuvent pas charger de temps sur un projet "Attente d'assignation/Bench". Veuillez choisir un autre projet.`,
  5068: `Les personnes ayant des fonctions de direction ne peuvent pas charger de temps sur un projet "Attente d'assignation/Bench". Veuillez choisir un autre projet.`,
  5069: `Les personnes assignées à une industrie ne peuvent pas charger de temps sur un projet "Attente d'assignation/Bench". Veuillez choisir un autre projet.`,
  5070: `Les stagiaires ne peuvent pas charger de temps sur un projet "Attente d'assignation/Bench". Veuillez choisir un autre projet.`,
  5071: `Etant actuellement considéré comme étant absent dans Career Settings, vous ne pouvez pas charger de temps sur un projet "Attente d'assignation/Bench". Veuillez contacter votre département RH pour corriger votre situation si besoin ou choisir un autre projet.`,
  5072: `Vous êtes déjà alloué à plein temps sur un autre projet pour ce jour. Merci de sélectionner un autre projet.`,
  5073: `Les prestataires ne peuvent pas charger de temps sur un projet "Attente d'assignation/Bench". Veuillez choisir un autre projet.`,
  5074: `Vous ne pouvez pas charger de temps sur un projet "Attente d'assignation/Bench" pour un jour de week-end.`,
  5075: `Vous n'êtes pas autorisé à saisir du temps "bench" sur une autre agence.`,
  5076: `La date de début et la date fin du projet ne sont pas mentionnées. Veuillez mettre à jour les informations du projet.`,
  5077: `Vous n'êtes pas autorisés à saisir des temps en dehors des dates de début ou de fin du projet. Veuillez entrer vos temps entre le {projectStartDate} et le {projectEndDate}.`,
  5078: `Le type de dossier associé à ce projet n'est pas configuré pour dériver l'indicateur de facturation. Merci de contacter l'administrateur du système`,
  5079: `Le dossier n'existe pas dans rOar. Merci de contacter l'administrateur système pour assistance.`,
  5080: `Timesheet entry is restricted for {jobID} since the job's brand has been updated.`,
  5081: `La saisie de temps n’est pas possible, cette date étant en dehors de la fenêtre autorisée`,
  5082: `La saisie de temps n’est pas possible, cette date étant en dehors de la fenêtre autorisée`,
  5085: `Veuillez sélectionner TéléTravail`,
  5087: `Timesheets are not allowed for selected date.`,
}
