<template>
  <div>
    <v-label for="commentInput"
      >{{ $t('timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelComments') }}
    </v-label>
    <validation-provider v-slot="{ errors }" rules="maxComment" name="Comment">
      <v-text-field
        id="commentInput"
        outlined
        dense
        :placeholder="
          $t(
            'timesheetUI.EntryFormLabels.TimesheetEntryHoursLabelCommentsPlaceholder'
          )
        "
        v-model="comment"
        :error-messages="errors"
        counter
        maxlength="125"
        @input="setHasTimeEntryChanged({ e: true, data: null })"
        aria-describedby="commentInputError"
      >
        <template v-slot:message="{ message }">
          <div aria-live="polite" id="commentInputError">
            {{ message }}
          </div>
        </template>
      </v-text-field>
    </validation-provider>
  </div>
</template>

<script>
import { ValidationProvider } from 'vee-validate'
import { mapMutations, mapState } from 'vuex'

export default {
  name: 'CommentField',
  components: {
    ValidationProvider,
  },
  computed: {
    ...mapState('storeTimesheets', ['commentValue', 'timesheetDetail']),
    comment: {
      get() {
        return this.commentValue
      },
      set(val) {
        console.log('yoyoyo')
        this.setCommentValue(val)
      },
    },
  },
  methods: {
    ...mapMutations('storeTimesheets', [
      'setCommentValue',
      'setHasTimeEntryChanged',
    ]),
  },

  mounted() {
    this.comment = this.timesheetDetail.comment
  },
}
</script>
