import moment from 'moment'
import { timesheetVisibilityOptionsMap } from '@Shared/types'
import { GLOBAL_FILTERS } from '@Shared/filters'
import { DEFAULT_PANELS } from '@/utils/jobHelper'

export default () => {
  return {
    loading: false,
    currentTime: moment(),
    userData: {},
    agencyData: {},
    userContracts: {},
    onBehalfOfMappings: [],
    timesheetJobs: [],
    timesheets: [],
    firstAvailableContract: {},
    altairCode: window.getParameterByName('AltairCode'),
    businessRole: window.getParameterByName('BusinessRole'),
    locale: window.CULTURE === 'fr-FR' ? 'fr' : 'en',
    altairCodeOBO: null,
    OBOUserName: null,
    navigateToDate: window.getParameterByName('Date'),
    loadPreviousWeekData: false,
    calendar: [],
    savingTimesheet: false,
    timesheetExists: false,
    timesheetDetail: null,
    timesheetDetailBillableIndicators: [],
    timesheetDetailFeatures: null,
    timesheetDetailFeaturesWorkCodes: null,
    timesheetSelectedJob: '',
    loadingBI: false,
    timesheetDialogVisibility: false,
    savedIds: [],
    overtimeCodes: {
      overtime: ['FLOT', 'REOT'],
      overtimeDouble: ['FDOT', 'RDOT'],
      regular: ['FLTI', 'RETI'],
      other: ['DEFA', 'FDOO', 'FLOO', 'FLTO', 'RDOO', 'REOO', 'RETO'],
    },
    loadingFeatures: false,
    beforeEdit: [],
    absenceModule: true,
    showDeleteError: false,
    deletingData: null,
    initialLoad: true,
    timesheetJobsOg: [],
    deDupTimesheetJobsOg: [],
    observer: null,
    validateAll: false,
    showSubmitConfirmation: false,
    submittedData: null,
    hasTimeEntryChanged: false,
    dialogWidth: '720px',
    selectedPPMTask: {},
    searchTimesheetJobs: '',
    showLocationAdressesDialog: false,
    performRemoveButtonFocus: false,
    isTimesheetBeingReviewed: false,
    timesheetDetailOld: {},
    fieldsForComparisonMandatory: [
      'durationValue',
      'durationPercentageValue',
      'comment',
      'workCode',
      'billableIndicator',
    ],
    isCorrectionValid: true,
    replaceDurationValue: false,
    isTimesheetBeingCorrected: false,
    showSelfConsentSubmitDialog: false,
    selfConsentData: {},
    showHideJobError: false,
    hideJobData: null,
    showEditHiddenJobError: false,
    editHiddenJobData: null,
    timesheetVisibilityOptionsMap,
    timesheetVisibilityFilter: timesheetVisibilityOptionsMap.visible,
    timesheetVisibilityOptions: [
      { text: 'Show All', value: timesheetVisibilityOptionsMap.showAll },
      { text: 'Visible Only', value: timesheetVisibilityOptionsMap.visible },
      { text: 'Hidden Only', value: timesheetVisibilityOptionsMap.hidden },
    ],
    initialLoadYear: moment(),
    inititalAgencyCode: '',
    IsPercentageByDay: {},
    isTimeEntryDrawerOpen: false,
    isTimeEntryDrawerExpanded: false,
    jobsSortOrder: 'BI - asc',
    PPMTasksByJobId: {},
    altairTasksByJobId: {},
    locationList: [],
    preferenceDialogOpen: false,
    userSettings: null,
    savingSettings: false,
    isInlineErrorResolved: {},
    minDateForTimesheetNavigation: '2023-02-27',
    showJobListPopupFromBannerLink: false,
    jobListAssignedJobsSearchKeyword: '',
    alertWeekErrors: [],
    filterOpen: false,
    globalSearchSelectedFilters: [],
    globalFilterOptions: [
      {
        label: GLOBAL_FILTERS.SELF_ASSIGNED,
        icon: 'mdi-account-outline',
        disableTarget: GLOBAL_FILTERS.ASSIGNED_JOB,
      },
      {
        label: GLOBAL_FILTERS.MY_AGENCY,
        icon: 'mdi-account-multiple-outline',
        disableTarget: GLOBAL_FILTERS.OTHER_AGENCIES,
      },
      {
        label: GLOBAL_FILTERS.OTHER_AGENCIES,
        icon: 'mdi-briefcase-outline',
        disableTarget: GLOBAL_FILTERS.MY_AGENCY,
      },
      {
        label: GLOBAL_FILTERS.ASSIGNED_JOB,
        icon: 'mdi-pencil-outline',
        disableTarget: GLOBAL_FILTERS.SELF_ASSIGNED,
      },
      {
        label: GLOBAL_FILTERS.FAVOURITES,
        icon: 'mdi-star-outline',
        disableTarget: '',
      },
    ],
    apiRequestCancelTokenSources: {},
    timesheetCellDetails: new Map(),
    showAssignedJobsCountInfoBanner: true,
    unFilteredShelvedJobs: [],
    preparingForAutoSave: {},
    updateCellValuesAfterTimsheetUpdateFromDrawer: Date.now(),
    activeCell: '',
    unfilteredTimesheets: [],
    unfilteredJobs: [],
    timeEntryError: [],
    copyRestOfWeekSelected: false,
    allowCopyRestOfWeek: false,
    expansionPanels: [...DEFAULT_PANELS],
    locationWFHSaveAction: {},
    pendingUserLocationSettings: null,
    pendingUserWFHSettings: null,
    updateCellStateAfterTimsheetUpdateFromLocation: [],
    navigationLoading: false,
    agenciesData: {},
    commentValue: '',
  }
}
